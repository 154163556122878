import React from 'react';
import classNames from 'classnames';
import {SectionSplitProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Carousel from "./Carousel";

const propTypes = {
    ...SectionSplitProps.types
}

const defaultProps = {
    ...SectionSplitProps.defaults
}

class FeaturesSplit extends React.Component {

    render() {

        const {
            className,
            topOuterDivider,
            bottomOuterDivider,
            topDivider,
            bottomDivider,
            hasBgColor,
            invertColor,
            invertMobile,
            invertDesktop,
            alignTop,
            imageFill,
            ...props
        } = this.props;

        const outerClasses = classNames(
            'features-split section',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
        );

        const innerClasses = classNames(
            'features-split-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
        );

        const splitClasses = classNames(
            'split-wrap',
            invertMobile && 'invert-mobile',
            invertDesktop && 'invert-desktop',
            alignTop && 'align-top'
        );

        const sectionHeader = {
            title: 'Our Products',
            paragraph: ''
        };

        return (
            <section
                {...props}
                className={outerClasses}
            >

                <div className="container" id={"products"}>
                    <div className={innerClasses}>

                        <SectionHeader data={sectionHeader} className="center-content"/>
                        <p className="center-content">
                            At Douce Rose, we want to bring the elegance of French Haute Couture to your high. Macarons
                            and chocolates are delicious on their own, but when combined with the French talent for
                            sinfully chic textures and tastes, we've hit a new high.
                        </p>
                        <div className={splitClasses}>

                            <div className="split-item" id={"rosedeparis"}>
                                <div className="split-item-content center-content-mobile">
                                    <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                                        Rose de Paris
                                    </h3>
                                    <p className="m-0 reveal-from-left" data-reveal-container=".split-item"
                                       data-reveal-delay="100">
                                        In our more modern version, we surround a traditional rose des sables and
                                        chocolate praline with delicate white chocolate rose petals to make a rose.
                                        Lastly, we dust gold over every petal.
                                        These gluten-free cookies have 30 mg of THC.
                                    </p>
                                </div>
                                <div className={
                                    classNames(
                                        'split-item-image center-content-mobile illustration-element-02 reveal-from-right',
                                        imageFill && 'split-item-image-fill'
                                    )}
                                     data-reveal-container=".split-item"
                                     data-reveal-delay="200">
                                    <Image
                                        src={require('./../../assets/images/rose-de-paris.png')}
                                        alt="Features split 01"
                                        width={528}
                                        height={396}/>
                                    {/*<div style={imgStyle}>*/}
                                    {/*  <Image*/}
                                    {/*    src={require('./../../assets/images/features-split-top-01.png')}*/}
                                    {/*    alt="Features split top 01"*/}
                                    {/*    width={654}*/}
                                    {/*    height={522} />*/}
                                    {/*</div>*/}
                                </div>
                            </div>

                            <div className="split-item" id={"macaron"}>
                                <div className="split-item-content center-content-mobile">
                                    <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-container=".split-item">
                                        French Macaron
                                    </h3>
                                    <p className="m-0 reveal-from-right" data-reveal-container=".split-item"
                                       data-reveal-delay="100">
                                        Each of our handcrafted macarons has 30 mg of THC. Our macarons are vegan. There
                                        is no gluten, additives, nor artificial food coloring in our macarons.
                                    </p>
                                    <br/>
                                    <h5 className="mt-0 mb-16 reveal-from-right" style={{textAlign: 'left'}}>Le
                                        Chocolat</h5>
                                    <p style={{textAlign: 'left'}}>Royal blue chocolate shells and a chocolate praline
                                        ganache</p>
                                    <h5 className="mt-0 mb-16 reveal-from-right" style={{textAlign: 'left'}}>La
                                        Pistache</h5>
                                    <p style={{textAlign: 'left'}}>Dark green color and pistachio praline ganache.</p>
                                    <h5 className="mt-0 mb-16 reveal-from-right" style={{textAlign: 'left'}}>La
                                        Framboise</h5>
                                    <p style={{textAlign: 'left'}}>Our pink raspberry perfection, raspberry shells with
                                        a sweet and creamy raspberry ganache.</p>
                                    <h5 className="mt-0 mb-16 reveal-from-right" style={{textAlign: 'left'}}>La
                                        Coco</h5>
                                    <p style={{textAlign: 'left'}}>Coconut shells and a coconut praline ganache.</p>
                                    <h5 className="mt-0 mb-16 reveal-from-right" style={{textAlign: 'left'}}>The 4 Flavors</h5>
                                    <p style={{textAlign: 'left'}}>Choose The 4 Flavors for a mini 15 mg taste of each of our flavors.</p>


                                    {/*<ul>*/}
                                    {/*    <li>Royal blue chocolate shells and a chocolate praline ganache</li>*/}
                                    {/*    </ul>*/}
                                    {/*    <ul>*/}
                                    {/*    <li>La Pistache - Dark green color and pistachio praline ganache</li>*/}
                                    {/*    <li>La Framboise - Our pink raspberry perfection, raspberry shells with a sweet*/}
                                    {/*        and creamy raspberry ganache.*/}
                                    {/*    </li>*/}
                                    {/*    <li>La Coco - Coconut shells and a coconut praline ganache</li>*/}
                                    {/*</ul>*/}
                                </div>
                                <div className={
                                    classNames(
                                        'split-item-image center-content-mobile illustration-element-03 reveal-from-left',
                                        imageFill && 'split-item-image-fill'
                                    )}
                                     data-reveal-container=".split-item"
                                     data-reveal-delay="200">
                                    <Image
                                        src={require('./../../assets/images/macaron.png')}
                                        alt="Features split 02"
                                        width={528}
                                        height={396}/>
                                    {/*<div style={imgStyle}>*/}
                                    {/*  <Image*/}
                                    {/*    src={require('./../../assets/images/features-split-top-02.png')}*/}
                                    {/*    alt="Features split top 02"*/}
                                    {/*    width={654}*/}
                                    {/*    height={521} />*/}
                                    {/*</div>*/}
                                </div>
                            </div>



                        </div>
                    </div>

                </div>

            </section>
        );
    }
}

const imgStyle = {
    position: 'absolute',
    width: '118.18%',
    maxWidth: '118.18%',
    top: '-7.07%',
    left: '-9.09%'
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;