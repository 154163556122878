import React from 'react';
import classNames from 'classnames';
import {SectionTilesProps} from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';

const propTypes = {
    ...SectionTilesProps.types
}

const defaultProps = {
    ...SectionTilesProps.defaults
}

class Team extends React.Component {

    render() {

        const {
            className,
            topOuterDivider,
            bottomOuterDivider,
            topDivider,
            bottomDivider,
            hasBgColor,
            invertColor,
            pushLeft,
            ...props
        } = this.props;

        const outerClasses = classNames(
            'team section center-content',
            topOuterDivider && 'has-top-divider',
            bottomOuterDivider && 'has-bottom-divider',
            hasBgColor && 'has-bg-color',
            invertColor && 'invert-color',
            className
        );

        const innerClasses = classNames(
            'team-inner section-inner',
            topDivider && 'has-top-divider',
            bottomDivider && 'has-bottom-divider'
        );

        const tilesClasses = classNames(
            'tiles-wrap',
            pushLeft && 'push-left'
        );

        const sectionHeader = {
            title: 'About Us',
            paragraph: 'Our mission is to revolutionize the cannabis edible market by introducing luxurious and elevated ' +
                'cannabis experiences into cannabis-infused products.'


        };

        const sectionHeader2 = {
            title: null,
            paragraph: 'Douce Rose edibles are handmade works of art, each lovingly crafted in California. ' +
                'We gain inspiration from the nation of the best pastry chefs the world has ever seen, and each bite is an experience of delight.'


        };

        const sectionHeader3 = {
            title: null,
            paragraph: 'Mikael and Eymeric are from France, Toulouse and Bordeaux respectively. ' +
                'Thanks to Mikael’s previous experiences in luxurious French cuisine and Eymeric’s background in finance, the two founders decided to work together to create Douce Rose. '


        };

        const partnerHeader = {
            title: "Partners",
            paragraph: 'Mikael and Eymeric are from France, Toulouse and Bordeaux respectively. ' +
                'Thanks to Mikael’s previous experiences in luxurious French cuisine and Eymeric’s background in finance, the two founders decided to work together to create Douce Rose. '


        };

        return (
            <section
                {...props}
                className={outerClasses}
            >
                <div className="container">
                    <div className={innerClasses}>
                        <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom"/>
                        <SectionHeader data={sectionHeader2} className="center-content reveal-from-bottom"/>
                        <div className={tilesClasses}>

                            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                                <div className="tiles-item-inner">
                                    <div className="team-item-header">
                                        <div className="team-item-image mb-24">
                                            <Image
                                                src={require('./../../assets/images/Eymeric-Chevalier.png')}
                                                alt="Team member 01"
                                                width={200}
                                                height={200}/>
                                        </div>
                                    </div>
                                    <div className="team-item-content">
                                        <h5 className="team-item-name mt-0 mb-4">
                                            Eymeric Chevalier
                                        </h5>
                                        <div className="team-item-role text-xs fw-500 mb-8">
                                            Co-Founder & CFO
                                        </div>
                                        {/*<p className="m-0 text-sm">*/}
                                        {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.*/}
                                        {/*</p>*/}
                                    </div>
                                </div>
                            </div>

                            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap"
                                 data-reveal-delay="150">
                                <div className="tiles-item-inner">
                                    <div className="team-item-header">
                                        <div className="team-item-image mb-24">
                                            <Image
                                                src={require('./../../assets/images/Mikael-Varin.png')}
                                                alt="Team member 02"
                                                width={200}
                                                height={200}/>
                                        </div>
                                    </div>
                                    <div className="team-item-content">
                                        <h5 className="team-item-name mt-0 mb-4">
                                            Mikael Varin
                                        </h5>
                                        <div className="team-item-role text-xs fw-500 mb-8">
                                            Co-Founder & CEO
                                        </div>
                                        {/*<p className="m-0 text-sm">*/}
                                        {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.*/}
                                        {/*</p>*/}
                                    </div>

                                </div>

                            </div>


                            <SectionHeader data={sectionHeader3} className="center-content reveal-from-bottom"/>


                        </div>
                        <h1>Partners</h1>
                        <div className={tilesClasses}>

                            <div className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                                <div className="tiles-item-inner">
                                    <div className="team-item-header">
                                        <div className="team-item-image mb-24">
                                            <Image
                                                src={require('./../../assets/images/louis-monterosso.png')}
                                                alt="Team member 01"
                                                width={200}
                                                height={200}/>
                                        </div>
                                    </div>
                                    <div className="team-item-content">
                                        <h5 className="team-item-name mt-0 mb-4">
                                            Louis Monterosso
                                        </h5>
                                        <div className="team-item-role text-xs fw-500 mb-8">
                                            Partner
                                        </div>
                                        {/*<p className="m-0 text-sm">*/}
                                        {/*  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod.*/}
                                        {/*</p>*/}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;