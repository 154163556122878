import React from 'react';
// import section header
import SectionHeader from '../components/sections/partials/SectionHeader';
// import sections
import HeroFull from '../components/sections/HeroFull02';
import Clients from '../components/sections/Clients';
import Team from '../components/sections/Team';
import GenericSection from '../components/sections/GenericSection';
import Roadmap from '../components/sections/Roadmap';
import Cta from '../components/sections/Cta';
// import some required elements
import Image from '../components/elements/Image';
import Input from '../components/elements/Input';
import ButtonGroup from '../components/elements/ButtonGroup';
import Button from '../components/elements/Button';
import Modal from '../components/elements/Modal';
import Accordion from '../components/elements/Accordion';
import AccordionItem from '../components/elements/AccordionItem';

class AboutUs extends React.Component {

  state = {
    demoModalActive: false
  }

  openModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ demoModalActive: false });
  }

  render() {

    const genericSection01Header = {
      title: 'Buttons - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection02Header = {
      title: 'Input forms - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection03Header = {
      title: 'Modal - Lorem ipsum is placeholder text commonly used.'
    }

    const genericSection04Header = {
      title: 'FAQ'
    }

    return (
      <React.Fragment>
        <HeroFull hasBgColor invertColor />
        {/*<Clients topDivider bottomDivider />*/}
        <Team />

        {/*<GenericSection topDivider className="illustration-section-06">*/}
        {/*  <div className="container-xs">*/}
        {/*    <h2 className="mt-0">This is where we can have broader messages about Douce Rose brand</h2>*/}
        {/*    <p>*/}
        {/*      Lorem ipsum dolor sit amet, <a href="#0">consectetur adipiscing elit</a>, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.*/}
        {/*    </p>*/}
        {/*    <p>*/}
        {/*      Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
        {/*    </p>*/}
        {/*    <figure>*/}
        {/*      <Image*/}
        {/*        className="image-larger"*/}
        {/*        src={require('./../assets/images/DouceRoseRectangleLargeCroissant.png')}*/}
        {/*        alt="Placeholder"*/}
        {/*        width={712}*/}
        {/*        height={400} />*/}
        {/*      <figcaption className="text-color-low">Douce Rose signature Macarons</figcaption>*/}
        {/*    </figure>*/}
        {/*    <h4>Unmatched Quality</h4>*/}
        {/*    <p>*/}
        {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.*/}
        {/*    </p>*/}
        {/*    <ul>*/}
        {/*      <li>Lorem ipsum dolor sit amet, consectetur.</li>*/}
        {/*      <li>Lorem ipsum dolor sit amet, consectetur.</li>*/}
        {/*      <li>Lorem ipsum dolor sit amet, consectetur.</li>*/}
        {/*    </ul>*/}
        {/*    <p>*/}
        {/*      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris.*/}
        {/*    </p>*/}
        {/*  </div>*/}
        {/*</GenericSection>*/}

        <GenericSection>
          <div className="container-xs" id="faq">
            <SectionHeader data={genericSection04Header} className="center-content" />
            <Accordion>
              <AccordionItem title="What Does Douce Rose Mean?" active>
                In France, people view cannabis as a "sweet drug." Our fun play on words, "Douce" is the French word for sweet. This term refers both to our delicious pastries and the cannabis inside.
              </AccordionItem>
              <AccordionItem title="How Do I Store My Macarons and Rose De Paris?">
                Make sure to keep your treats refrigerated. Try to eat them within a month, although if you'd like to keep them longer, you can always freeze them. Before eating, always leave your treats to warm up to room temperature, so you get the best experience of rich tastes and texture.
              </AccordionItem>
              <AccordionItem title="How Much THC Should I Consume?">
                Our packaging comes with a chart on the best way to split up your treats for the high you deserve.
If this is your first time trying edibles, we recommend starting small. Try 10 mg to 15 mg. See how it affects you, how much you like the feelings, before moving to a larger quantity.
Everyone experiences THC differently, with some people having a naturally high tolerance and others having little to no tolerance. It may take anywhere from 30 minutes to 2 hours to feel the effects of THC. So be patient, and wait at least two hours before consuming more if you're not feeling the high. The effects usually last a couple of hours.
              </AccordionItem>
              <AccordionItem title="Where Can I Buy Your Products?">
                Because each of our treats is a special gift for you, we package them in flowers. So look for those when you're browsing, and check out our map below to see what dispensaries sell your next indulgence.
              </AccordionItem>
              {/*<AccordionItem title="Nisi porta lorem mollis aliquam ut.">*/}
              {/*  Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.*/}
              {/*</AccordionItem>*/}
            </Accordion>
          </div>
        </GenericSection>

        {/*<Roadmap topDivider />*/}
        <Cta hasBgColor  split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

// inline style
const formStyle = {
  maxWidth: '420px',
  margin: '0 auto'
}

export default AboutUs;