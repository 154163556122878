import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroFull extends React.Component {

  state = {
    videoModalActive: false
  }
  openModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: true });
  }

  closeModal = (e) => {
    e.preventDefault();
    this.setState({ videoModalActive: false });
  }

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content" style={inlineStyle()}>
              {/*<div className="container-xs">*/}
                <div style={{padding: '1px'}}>
                <Image
                  // className="has-shadow"
                  src={require('./../../assets/images/douceroselogo.png')}
                  alt="Features placeholder"
                  width={400}
                  height={400} />
              </div>
                <h5 className="m-0 reveal-from-top">
                  Artisan French Pastries with the perfect infusion of cannabis for delicious decadence.
                </h5>
              </div>
            </div>
            {/*<div className="hero-figure illustration-element-01 reveal-from-bottom" data-reveal-delay="150">*/}
            {/*  <a*/}
            {/*    data-video="https://player.vimeo.com/video/174002812"*/}
            {/*    href="#0"*/}
            {/*    aria-controls="video-modal"*/}
            {/*    onClick={this.openModal}*/}
            {/*  >*/}
            {/*    <Image*/}
            {/*      className="has-shadow"*/}
            {/*      src={require('./../../assets/images/video-placeholder.png')}*/}
            {/*      alt="Video"*/}
            {/*      width={896}*/}
            {/*      height={502} />*/}
            {/*  </a>*/}
            {/*</div>*/}
            {/*<Modal*/}
            {/*  id="video-modal"*/}
            {/*  show={this.state.videoModalActive}*/}
            {/*  handleClose={this.closeModal}*/}
            {/*  video="https://player.vimeo.com/video/174002812"*/}
            {/*  videoTag="iframe" />*/}
          </div>
        {/*</div>*/}
      </section>
    );
  }
}

// inline style
const inlineCss = {
  marginBottom: '40px'
}

const inlineStyle = function () {
  if (window.innerWidth > 641) {
    return inlineCss;
  }
};
HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;