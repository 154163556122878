import React from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import Button from '../elements/Button';
import Logo from "../layout/partials/Logo";
import Image from "../elements/Image";
import heroBackground from './../../assets/images/douce-rose-hero-back.png'
import Carousel from "./Carousel";
const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

class HeroFull extends React.Component {

  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,      
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className
    );


    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider'
    );

    return (
      <section
        {...props}
        className={outerClasses}
      >

        <div className="container-sm">

          <div className={innerClasses}>
            <div className="hero-content" style={inlineStyle()}>

              {/*<h1 className="mt-0 mb-16 reveal-from-top">*/}
              {/*  Landing template for startups*/}
              {/*</h1>*/}
              {/*<div className="container-sm reveal-from-bottom" data-reveal-delay="300" data-reveal-offset="0" style={placeholderStyle}>*/}
              <div style={{padding: '1px'}}>
                <Image
                  // className="has-shadow"
                  src={require('./../../assets/images/douceroselogo.png')}
                  alt="Features placeholder"
                  width={400}
                  height={400} />
              </div>
            {/*</div>*/}
              <div className="container-xs">
                <h3 className="mt-0 mb-16 reveal-from-top">
                Get High like Versailles
              </h3>
                <p className="m-0 mb-32 reveal-from-top" data-reveal-delay="150">
                  Artisan French Pastries with the perfect infusion of cannabis for delicious decadence.
                </p>
                {/*<div className="reveal-from-top" data-reveal-delay="300" style={{padding: '2px'}}>*/}
                {/*  <Button tag="a" color="primary" href="/about">*/}
                {/*    Learn More*/}
                {/*  </Button>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </div>

      </section>
    );
  }
}

// inline style
const inlineCss = {
  marginBottom: '40px'
}

const inlineStyle = function () {
  if (window.innerWidth > 641) {
    return inlineCss;
  }
};

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;