import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import Pricing from '../components/sections/Pricing';
import Testimonial from '../components/sections/Testimonial';
import Cta from '../components/sections/Cta';
import heroBackground from "../assets/images/douce-rose-hero-back.png";
import Carousel from '../components/sections/Carousel'

class Home extends React.Component {
  render() {
    return (
      <React.Fragment>
          {/*hasBgColor invertColor*/}
        <HeroFull hasBgColor invertColor />
        {/*<div className="flex flex-col min-h-screen overflow-hidden">*/}
        {/*<Carousel  />*/}
        {/*</div>*/}

        {/*<FeaturesTiles />*/}
        <FeaturesSplit invertMobile topDivider imageFill/>
        {/*<Pricing hasBgColor invertColor pricingSwitcher />*/}
        {/*<Testimonial />*/}
        <Cta hasBgColor invertColor split className="has-bg-color-cut" />
      </React.Fragment>
    );
  }
}

export default Home;